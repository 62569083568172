a{
    text-decoration: none;
    color: black;
}

li{
    list-style: none;
}


.mainnav{
   display: flex;
   justify-items: center;
   
   

}
.logo{
    display: flex;
    justify-content: start;
    align-items: center;
    grid-column: 2/3;
}

.menulink ul{
    grid-column: 3/4;
}

.menulink ul{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.menulink ul li{
    margin-right: 4rem;
   
}
.excesslink{
    grid-column: 4/5;
}
.excesslink ul{
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: end;

}
.excesslink ul li:first-child{
    margin-right: 4rem;

}
/* .navbar_scrolled{
    display: grid;
    grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
    background-color: white;
   
} */

.navbar{
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 80px;
}
.logInButton{
    background-color: #D63232;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 100px;
}
.profiledropdown {
    position: relative;
    display: inline-block;
  }
  
  .profileicon {
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    border-radius: 50%; /* Makes it a circular icon */
    overflow: hidden;
  }
  
  .profileicon img {
    width: 100%;
    height: 100%;
  }
  
  .dropdowncontent {
    display: none;
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 40px; /* Adjust the distance from the icon */
    right: 0; /* Adjust the alignment as needed */
  }
  
  .dropdowncontent a {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: #333;
  }
  
  .dropdowncontent a:hover {
    background-color: #f2f2f2;
  }
  
  .profiledropdown:hover .dropdowncontent {
    display: block;
  }