.Column{
    position: relative;
}
.SearchBar{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    height: 80px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
}
.maindiv{
    padding-top: 150px;
    padding-left: 200px;
    padding-right: 200px;
}
.CustomInput{
  border: none;
  width: 70%;
  height: 35%;
  outline: none;
  margin-left:10px ;
  margin-right:10px ;
}
.customBage{
    background-color: 	#ffd7b5;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}
.customBageText{
    padding-top: 2px;
    color: #ff6700;
    font-weight: bold;
}
.jobBox{
    border: 1px solid 	#fee9d6;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: transform 0.2s;
    background-color: #f8f2ed;

}
.jobBox:hover{
    transform: translateY(-5px);
    border: 1px solid 	#ffb38a;
}
.customApplyButton{
    color: #ff9248;
    background-color: #fee9d6;
    border-radius: 2px;
    border: none;
    height: 50px;
}
.customApplyButton:hover{
    background-color: #ff6700;
}
.customSearchbutton{
    background-color: #bb1405;
    display: flex;
    height: 50px;
    align-items: center;
    margin-right: 10px;
    border: none;
    transition: transform 0.1s;
    color: white;
        border-radius: 5px;
}

.customSearchbutton:hover{
    transform: translateY(-2px);
    background-color: #9D1306;
}
.Skills{
    height: auto;
    width: auto;
    padding: 10px;
    margin-right: 10px;
    background-color:antiquewhite;
    align-items: center;
    border-radius: 5px;
}
.dividerContainer{
    background-color: #ffd7b5;
    height: "400px";
    border-radius: "20px";
}
@media (min-width: 1199.98px ) and (max-width:1299.98px) {
    .SearchBar{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 90%;
        height: 70px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 50px;
        align-items: center;
        margin-right: 10px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .dividerContainer{
        background-color: #ffd7b5;
        height: "400px";
        border-radius: "20px";
    }
 }
 @media (min-width: 991.98px ) and (max-width:1199.98px){
    .SearchBar{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 90%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 40px;
        align-items: center;
        margin-right: 10px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .dividerContainer{
        background-color: #ffd7b5;
        height: "400px";
        border-radius: "20px";
    }
 }
 @media (min-width: 768px ) and (max-width:991.98px){
    .containerImage{
        height: 100px;
        width: 100px;
    }
    .SearchBar{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 90%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 40px;
        align-items: center;
        margin-right: 10px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .dividerContainer{
        background-color: #ffd7b5;
        height: "400px";
        border-radius: "20px";
    }
 }
 @media (min-width: 575.98px) and (max-width:768px){
    .maindiv{
        padding-top: 150px;
        padding-left: 100px;
        padding-right: 100px;
    }
    .containerImage{
        height: 100px;
        width: 100px;
    }
    .SearchBar{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 90%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 40px;
        align-items: center;
        margin-right: 10px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .dividerContainer{
        background-color: #ffd7b5;
        height: "400px";
        border-radius: "20px";
        width: 400px;
    }

 }
 @media (min-width:0px) and (max-width: 575.98px){
    .maindiv{
        padding-top: 150px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .containerImage{
        height: 100px;
        width: 100px;
    }
    .SearchBar{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 90%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 40px;
        align-items: center;
        margin-right: 10px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .dividerContainer{
        background-color: #ffd7b5;
        height: "400px";
        border-radius: "20px";
        width: 400px;
    }
 }

