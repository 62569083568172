.updownanimation{
    animation-name: infiniteupdown;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .3s;
}

@keyframes infiniteupdown {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-40px);
    }
    100%{
        transform: translateY(0px);
    }
}

.placeholderColor::placeholder {
    color: #D3D3D3; /* Change this to the desired color */
}
