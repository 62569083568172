.imagecontent{
    top: 0;
    height: auto;
    width: 100%;
    position: absolute; 
}
.navbarbackgroundColor{
   background: transparent;
}
.bgImage{
    width: 100%;

}
.navbarwrapper{
    width: 100%;
    position: fixed;
    z-index: 1000;
     
}
a{
    text-decoration: none;
    color: black;
}

li{
    list-style: none;
}


.mainnav{
    display: grid;
    grid-template-columns: 10rem 1fr 3fr 2fr 10rem;
  
    
}
.logo{
    display: flex;
    justify-content: start;
    align-items: center;
    grid-column: 1.5/3;
    padding: 10px;
}

.menulink ul{
    grid-column: 3/4;
    background-color: black;

   
}

.menulink ul{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.menulink ul li{
    margin-right: 4rem;
    display: none;
}
.excesslink{
    grid-column: 4/5;
}
.hamberge{

    background-color: #9D1306;
}
.excesslink ul{
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #bb1405;
    display: none;

}
.excesslink ul li:first-child{
    margin-right: 4rem;

}
.SearchBar{
    width: 100%;
    height: 80px;
    border-radius: 5px;
    background-color: white;
    margin-top: 50px;
    display: flex;
    z-index: -1;
    align-items: center;
    justify-content: space-around;
    -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
}
.CustomInput{
    border: none;
    width: 70%;
    height: 35%;
    outline: none;
    margin-left:10px ;
    margin-right:10px ;
}
.customSearchbutton{
    background-color: #bb1405;
    display: flex;
    height: 50px;
    align-items: center;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    color: white;
    border-radius: 5px;
    transition: transform 0.1s;
}

.customSearchbutton:hover{
    transform: translateY(-2px);
    background-color: #9D1306;
}

.jobCategoryRow{
    margin-top: 30px;
}

.jobCategory{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
 
}
.jobBox{
    border: 1px solid #fee9d6;
    border-radius: 5px;
    align-items: center;
    display: flex;
    height:'20px';
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 50px;
    width: 200px;
    cursor: pointer;
    margin-top: 10px;
    
}
.jobBox p{
    margin-left: 5px;
    margin-top: 10px;
}

.jobBox:hover{
    border: 1px solid #ff9248;
    color: #ff6700;
    font-weight: bold;
}
.jobBox{
    border: 1px solid 	#fee9d6;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: transform 0.2s;
    background-color: #f8f2ed;
    width: auto;

}
.Skills{
    height: auto;
    width: auto;
    padding: 10px;
    margin-right: 10px;
    background-color:antiquewhite;
    align-items: center;
    border-radius: 5px;
}
.HeroSection{
    margin-top: 100px;
    margin-left: 150px;
    margin-right: 150px;
    height: 550px;
    
}
.HeroText{
    font-size: 60px;
    text-transform: capitalize;
    align-items: start;

}
.HeroRow{
    margin-top: 100px;
}
.HeroDescription{
    margin-top: 30px;
}
.HeroImage{
    /* height: "300px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", */
    height: 420px;
}

.dividerContainer{
    padding-left: 200px;
    padding-right: 200px;
}
.divider{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #fee9d6;
    background-color: #f8f2ed;
}
.popularSearch{
    margin-top: 20px;
    display: flex;
}
.popularSearch strong{
    color: #526066
}
.popularSearch p{
    color: #727673;
    text-decoration: underline;
}
.applyNowButton{
    height: 50px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 5px;
    border-radius: 5px;
    border: none;
    background-color: #ff6700;
}
.applyNowButtonText{
    padding: 9px 15px 15px 9px;
    font-size: 12px;
    color: white;
}

@media (min-width: 1199.98px ) and (max-width:1299.98px) { 
    .HeroSection{
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
    }
    .HeroText{
        font-size: 30px;
        margin-top: 20px;
    }
    .HeroRow{
        margin-top: 10px;
    }
    .HeroDescription{
        margin-top: 1px;
        font-size: 16px;
    }
    .SearchBar{
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        margin-top: 0px;
        display: flex;
        z-index: -1;
        align-items: center;
        justify-content: start;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .HeroImage{
        height: 300px;
    }
    .dividerContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .divider{
        display: flex;
        justify-content: space-between;
        border: 1px solid #fee9d6;
        background-color: #f8f2ed;
        width: auto;
    }
    .dividerLeftImage{
        width: 100px;
        height: 100px;
        margin-top: 50px;
    }
    .dividerRightImage{
        width: 150px;
        height: 150px;
        margin-top: 30px;
    }
    .dividerCenterparafirst{
        text-transform: uppercase;
        margin-left: 20px;
        margin-top: 20px;
    }
    .dividerCenterheading{
        font-size: x-large;
    }
    .dividerCenterparasecond{
        margin-left: 30px;
        margin-right:0px;
        width: max-content;
    }
    .imagecontent{
        top: 0;
        height: auto;
        width: 100%;
        position: absolute;
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 40px;
        align-items: center;
        margin-right: 0px;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .popularSearch{
        margin-top: 30px;
        display: flex;
    }
    .popularSearch strong{
        color: #526066;
        font-size: 18px;

    }
    .popularSearch p{
        color: #727673;
        text-decoration: underline;
        font-size: 18px;
    }
    .applyNowButton{
        height: 50px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 5px;
        border-radius: 5px;
        border: none;
        background-color: #ff6700;
    }
    .applyNowButtonText{
        padding: 9px 15px 15px 9px;
        font-size: 12px;
        color: white;
    }
   
 }
@media (min-width: 991.98px ) and (max-width:1199.98px) { 
    .HeroSection{
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
    }
    .HeroText{
        font-size: 20px;
        margin-top: 0px;
    }
    .HeroRow{
        margin-top: 0px;
    }
    .HeroDescription{
        margin-top: 1px;
        font-size: 12px;
    }
    .SearchBar{
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        margin-top: 0px;
        display: flex;
        z-index: -1;
        align-items: center;
        justify-content: start;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .HeroImage{
        height: 200px;
    }
    .dividerContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .divider{
        display: flex;
        justify-content: space-between;
        border: 1px solid #fee9d6;
        background-color: #f8f2ed;
        width: auto;
    }
    .dividerLeftImage{
        width: 100px;
        height: 100px;
        margin-top: 50px;
    }
    .dividerRightImage{
        width: 150px;
        height: 150px;
        margin-top: 30px;
    }
    .dividerCenterparafirst{
        text-transform: uppercase;
        margin-left: 20px;
        margin-top: 20px;
    }
    .dividerCenterheading{
        font-size: medium;
    }
    .dividerCenterparasecond{
        margin-left: 30px;
        margin-right:0px;
        width: max-content;
    }
    .imagecontent{
        top: 0;
        height: auto;
        width: 100%;
        position: absolute;
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 30px;
        align-items: center;
        margin-right: 10px;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .popularSearch{
        margin-top: 10px;
        display: flex;
    }
    .popularSearch strong{
        color: #526066;
        font-size: 12px;

    }
    .popularSearch p{
        color: #727673;
        text-decoration: underline;
        font-size: smaller;
    }
    .applyNowButton{
        height: 50px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 5px;
        border-radius: 5px;
        border: none;
        background-color: #ff6700;
    }
    .applyNowButtonText{
        padding: 9px 15px 15px 9px;
        font-size: 12px;
        color: white;
    }
   
 }
@media (min-width: 768px ) and (max-width:991.98px) { 
    .HeroSection{
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
    }
    .HeroText{
        font-size: 20px;
        margin-top: 0px;
    }
    .HeroRow{
        margin-top: 0px;
    }
    .HeroDescription{
        margin-top: 1px;
        font-size: 12px;
    }
    .SearchBar{
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        margin-top: 0px;
        display: flex;
        z-index: -1;
        align-items: center;
        justify-content: start;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .HeroImage{
        display: none;
    }
    .dividerContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .divider{
        display: flex;
        justify-content: space-between;
        border: 1px solid #fee9d6;
        background-color: #f8f2ed;
        width: auto;
    }
    .dividerLeftImage{
        width: 100px;
        height: 100px;
        margin-top: 50px;
    }
    .dividerRightImage{
        width: 150px;
        height: 150px;
        margin-top: 30px;
    }
    .dividerCenterparafirst{
        text-transform: uppercase;
        margin-left: 20px;
        margin-top: 20px;
    }
    .dividerCenterheading{
        font-size: medium;
    }
    .dividerCenterparasecond{
        margin-left: 30px;
        margin-right:0px;
        width: max-content;
    }
    .imagecontent{
        top: 0;
        height: auto;
        width: 100%;
        position: absolute;
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 30px;
        align-items: center;
        margin-right: 10px;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .popularSearch{
        margin-top: 10px;
        display: flex;
    }
    .popularSearch strong{
        color: #526066;
        font-size: 12px;

    }
    .popularSearch p{
        color: #727673;
        text-decoration: underline;
        font-size: smaller;
    }
    .applyNowButton{
        height: 50px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 5px;
        border-radius: 5px;
        border: none;
        background-color: #ff6700;
    }
    .applyNowButtonText{
        padding: 9px 15px 15px 9px;
        font-size: 12px;
        color: white;
    }
   
 }
 @media (min-width: 575.98px) and (max-width:768px) { 
    .bCategory{
        font-size: 20px;
    }
    .dividerContainer{
        padding-left: 10px;
        padding-right: 100px;
        
    }
    .HeroSection{
        height: auto;
        margin-right: 50px;
        margin-left: 50px;
    }
    .logoImg{
        width: 50px;
        height: 50px;
    }
    .brand{
        font-size: 20px;
    }
    .HeroText{
        font-size: 12px;
        margin-top: 0px;
    }
    .HeroRow{
        margin-top: 0px;
    }
    .HeroDescription{
        margin-top: 1px;
        font-size: 11px;
    }
    .HeroImage{
        display: none;
    }
    .SearchBar{
        width: 70%;
        height: 40px;
        border-radius: 5px;
        background-color: white;
        margin-top: 0px;
        display: flex;
        z-index: -1;
        align-items: center;
        justify-content: start;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .dividerContainer{
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 50px;
        width: 100%;
        
    }
    .customSearchbutton{
        background-color: #bb1405;
        display: flex;
        height: 30px;
        align-items: center;
        margin-right: 10px;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .HeroImage{
       height: 100px;
    }
    .divider{
        display: flex;
        justify-content: space-between;
        border: 1px solid #fee9d6;
        margin-right: 20px;
        width: auto;
    }
    .dividerLeftImage{
        width: 100px;
        height: 100px;
        margin-top: 30px;
    }
    .dividerRightImage{
        width: 100px;
        height: 100px;
        margin-top: 20px;
    }
    .dividerCenterparafirst{
        text-transform: uppercase;
        margin-left: 10px;
        margin-top: 10px;
        
    }
    .dividerCenterheading{
        font-size: large;
    }
    .dividerCenterparasecond{
        margin-left: 30px;
        margin-right:0px;
         width: max-content;
    }
    .popularSearch{
        margin-top: 5px;
        display: flex;
    }
    .popularSearch strong{
        color: #526066;
        font-size: 10px;

    }
    .popularSearch p{
        color: #727673;
        text-decoration: underline;
        font-size: 9px;
    }
    .browsecategoryDivider{
        margin-top: 40px;
    }
    .applyNowButton{
        height: 50px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 5px;
        border-radius: 5px;
        border: none;
        background-color: #ff6700;
    }
    .applyNowButtonText{
        padding: 9px 3px 3px 3px;
        font-size: 12px;
        color: white;
    }
    .jobBox{
        border: 1px solid 	#fee9d6;
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        transition: transform 0.2s;
        background-color: #f8f2ed;
        width: 100%;
    }
    
  }
  @media (min-width:0px) and (max-width: 575.98px) {
    .browseCategoryDivider{
        margin-top: 50px;
    }
    .applyNowButton{
        height: 40px;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 5px;
        border-radius: 5px;
        border: none;
        background-color: #ff6700;
    }
    .applyNowButtonText{
        padding: 9px 3px 3px 3px;
        font-size: 12px;
        color: white;
    }

    .jobCategoryBox{
        border: 1px solid #fee9d6;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
        height:'20px';
        padding-left: 10px;
        padding-right: 10px;
        width: 200px;
        cursor: pointer;
        margin-top: 10px;
        
    }
    .jobCategoryBox p{
        margin-left: 5px;
        margin-top: 10px;
    }
    
    .jobCategoryBox:hover{
        border: 1px solid #ff9248;
        color: #ff6700;
        font-weight: bold;
    }
   .jobBox{
    border: 1px solid 	#fee9d6;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: transform 0.2s;
    background-color: #f8f2ed;
    width: 100%;
    }
    .logoImg{
        width: 30px;
        height: 30px;
    }
    .brand{
        font-size: 16px;
    }
    .HeroSection{
       
        height: auto;
        margin-right: 50px;
        margin-left: 50px;margin-top: 50px;
    }
    .HeroText{
        font-size: 10px;
        margin-top: 0px;
    }
    .HeroRow{
        margin-top: 0px;
    }
    .HeroDescription{
        margin-top: 1px;
        font-size: 10px;
    }
    
    .SearchBar{
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background-color: white;
        margin-top: 0px;
        display: flex;
        z-index: -1;
        align-items: center;
        justify-content: start;
        -webkit-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 85px -36px rgba(0,0,0,0.75);
    }
    .customSearchbutton{
        display: flex;
        height: 30px;
        align-items: center;
        margin-right: 10px;
        padding-left: 5px;
        padding-right: 5px;
        border: none;
        color: white;
        border-radius: 5px;
        transition: transform 0.1s;
    }
    .HeroImage{
       display: none;
    }
    .dividerContainer{
        margin-top: 50px;
        width: 100%;
        
    }
    .divider{
        display: flex;
        justify-content: space-between;
        border: 1px solid #fee9d6;
        width: auto;
    }
    .dividerLeftImage{
        width: 30px;
        height: 30px;
        margin-top: 30px;
    }
    .dividerRightImage{
        width: 50px;
        height: 50px;
        margin-top: 20px;
        margin-right: 50px;
    }
    .dividerCenterparafirst{
        text-transform: uppercase;
        margin-left: 10px;
        font-size: 12px;
        /* margin-top: 10px; */
        
    }
    .dividerCenterheading{
        font-size: 12px;
    }
    .dividerCenterparasecond{
        margin-left: 10px;
        margin-right:0px;
        font-size: 10px;
        width: max-content;
    }
    .bCategory{
        font-size: 20px;
       
    }
    .popularSearch{
        margin-top: 5px;
        display: flex;
    }
    .popularSearch strong{
        color: #526066;
        font-size: 9px;

    }
    .popularSearch p{
        color: #727673;
        text-decoration: underline;
        font-size: 8px;
    }
   
    
   }
